import { ChangeEvent, FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { format } from "date-fns";
import Select, { GroupBase, MultiValue, OptionsOrGroups, PropsValue } from "react-select";
import { useDebouncedCallback } from "use-debounce";

import HubInputField from "../inputType";
import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { DiagnosisResponseProps, CaseStepOneProps, ProcedureRequestProps } from "./types";
import { dataProduct } from "src/constants/contact";
import { GENVISC_QUANTITY, TRIVISC_QUANTITY } from "src/constants/cases";




const CaseStepOne: FC<CaseStepOneProps> = ({ control, isVisible, watch, setValue, getValues }): JSX.Element => {
  const drug_name = watch("prescription_attributes.drug_name");
  const diagnosisCode = watch("prescription_attributes.diagnosis_code");
  const directPurchase = watch("prescription_attributes.directPurchase");
  const autoDirectPurchase = watch("prescription_attributes.autoDirectPurchase");
  const benefitOnly = watch("prescription_attributes.benfitOnly");

  useEffect(() => {
    let newCategory;
    if (directPurchase)
      newCategory = 'pdp'
    else if (autoDirectPurchase)
      newCategory = 'pdp_medrx'
    else if (benefitOnly)
      newCategory = 'medrx'

    if (getValues("category") !== newCategory) {
      setValue("category", newCategory || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directPurchase, autoDirectPurchase, benefitOnly]);


  /**
   * @GetProcedureListing API to call getProcedureListing
   * @first initializing useFetch() hook
   * @second error message handler
   */
  const { data } = useFetch<ProcedureRequestProps>(APIEndpoints.GET_PROCEDURE_DROPDOWN);






  /**
   * @SearchDiagnosisBySearchValue search by API for diagnosis
   * @first initialize useFetch() with diagnosis
   * @second search diagnosis by search term
   */
  const { loading: diagnosisLoader, data: rfcListing, mutate: getDiagnosis, removeErrorAndData: clearDiagnosisData } =
    useFetch<DiagnosisResponseProps>(APIEndpoints.GET_DIAGNOSIS_DROPDOWN);

  /** @second */
  const searchDiagnosis = (search: string) => {
    if (typeof search === "string" && search.length >= 2) {
      clearDiagnosisData();
      getDiagnosis(null, null, { search });
    }
  };

  const debounced = useDebouncedCallback(searchDiagnosis, 1500);





  useEffect(() => {
    if (diagnosisCode || (rfcListing && rfcListing.data)) {
      setTimeout(() => clearDiagnosisData(), 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisCode]);

  const changeDirectPurchase = (onChange: (val: boolean) => void, inputEvent: ChangeEvent<HTMLInputElement>) => {
    setValue("prescription_attributes.directPurchase", false);
    setValue("prescription_attributes.autoDirectPurchase", false);
    setValue("prescription_attributes.benfitOnly", false);
    onChange(inputEvent.target.value === "on" ? true : false);
  };


  //:- Changing quantity as drug name changes in product dropdown
  // useEffect(() => {
  //   if (drug_name)
  //     setValue("prescription_attributes.qty", 3);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [drug_name]);



  return (
    <div className={`card mb-3 ${!isVisible ? 'd-none' : ''}`}>
      <div className="card-header">
        <h4 className="m-0">Getting Started</h4>
      </div>

      <div className="card-body">
        <p>
          In order to process your request for benefit investigation, the following
          information must be completed. All information is kept confidential according
          to HIPAA guidelines. Please fill out the fields below as completely as possible.
          Missing fields may result in a delay to processing patient benefits in a timely manner.
        </p>

        <div className="row">
          <div className="col-lg-6">
            <Controller
              name="procedure_attributes.cpt_code"
              control={control}
              defaultValue=""
              render={({field, fieldState: {error}}) => (
                <HubInputField
                  inputId="procedure_attributes.cpt_code"
                  inputLabel="Procedure (CPT)"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  inputPlaceholder="Select a Procedure ..."
                  dropdownData={
                    (data ?
                      data.data.map(
                        (procedure) => ({
                          value: procedure.code,
                          label: `${procedure.code} - ${procedure.description}`
                        })
                      ) : []
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
                name="prescription_attributes.drug_name"
                control={control}
                defaultValue=""
                render={({field, fieldState: {error}}) => (
                    <HubInputField
                        inputId="prescription_attributes.drug_name"
                        inputLabel="Product"
                        isRequired={true}
                        isRowBased={true}
                        isRowInsideAnother={true}
                        field={field}
                        error={error}
                        isDropdown={true}
                        isSearchable={false}
                        inputPlaceholder="Select a Product ..."
                        dropdownData={
                          (dataProduct ?
                                  dataProduct.map(
                                      (procedure) => ({value: procedure.value, label: `${procedure.name}`})
                                  ) : []
                          ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                        }
                    />
                )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="row mb-3">
              <label htmlFor="prescription_attributes.diagnosis_code"
                     className="form-label col-lg-4 col-md-4 col-12">
                Diagnosis Code *
              </label>
              <div className="col-lg-8 col-md-8 col-12">
                <Controller
                    name="prescription_attributes.diagnosis_code"
                    control={control}
                    defaultValue={[]}
                    render={({field, fieldState: {error}}) => (
                        <>
                          <Select
                              options={
                                (rfcListing && rfcListing.data && rfcListing.data.length > 0 ?
                                    rfcListing.data.map((option) => ({value: option.code, label: option.fullname}))
                                    : []) as unknown as OptionsOrGroups<string, GroupBase<string>>
                              }
                              id="prescription_attributes.diagnosis_code"
                              isSearchable={true}
                              isMulti={true}
                              placeholder="Enter Diagnosis Code"
                              {...field}
                              noOptionsMessage={() => diagnosisLoader ? "Loading..." : "Please enter 2 or more characters"}
                              value={
                                field.value.map((item) => ({
                                  value: item,
                                  label: item,
                                })) as unknown as MultiValue<unknown>
                              }
                              onChange={
                                (val) => {
                                  field.onChange(val.map((item: unknown) => (item as { value: string; }).value))
                                }
                              }
                              onInputChange={debounced}
                          />

                          {error && error.message ? (
                              <div className="text-danger text-small mt-2">{error.message}</div>
                          ) : null}
                        </>
                    )}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Controller
              name="prescription_attributes.qty"
              control={control}
              defaultValue={0}
              render={({ field, fieldState: { error } }) => (
                <div className="row mb-3">
                  <label
                    htmlFor="prescription_attributes.qty"
                    className="form-label col-lg-4 col-md-4 col-12"
                  >
                    Qty *
                  </label>
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="input-group">
                      {drug_name !== "" && !directPurchase && !autoDirectPurchase ? (
                        <Select
                          isSearchable={false}
                          className="form-control w-max p-0 border-0"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderBottomRightRadius: 0,
                              borderTopRightRadius: 0,
                            })
                          }}
                          placeholder="Select Quantity..."
                          options={
                            (drug_name === "GenVisc" ? GENVISC_QUANTITY : TRIVISC_QUANTITY) as unknown as OptionsOrGroups<number, GroupBase<number>>
                          }
                          {...field}
                          value={((drug_name === "GenVisc" ? GENVISC_QUANTITY : TRIVISC_QUANTITY).find((qty) => qty.value === field.value) || 0) as unknown as PropsValue<number>}
                          onChange={(value) => {
                            field.onChange((value as unknown as {
                              value: number;
                              label: string;
                            }).value);
                          }}
                        />
                      ) : (
                        <input
                          type="text" id="prescription_attributes.qty"
                          style={{ zIndex: 0 }}
                          className="form-control" disabled={(!directPurchase && !autoDirectPurchase) || drug_name === ""}
                          {...field}
                        />
                      )}
                      <span className="input-group-text" id="basic-addon2">injections</span>
                    </div>
                    {error && error.message ? (
                      <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2"></div>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Controller name="prescription_attributes.note" control={control} defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="prescription_attributes.note"
                                isRowBased={true}
                                isRowInsideAnother={true}
                                isRequired={false}
                                field={field}
                                inputLabel="Notes"
                                inputPlaceholder="Note Shared With Patient"
                                error={error}
                            />
                        )}
            />
          </div>
          <div className="col-lg-6">
            <Controller name="prescription_attributes.direction" control={control} defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="prescription_attributes.direction"
                                isRowBased={true}
                                isRequired={false}
                                isRowInsideAnother={true}
                                field={field}
                                inputLabel="Directions"
                                inputPlaceholder=""
                                error={error}
                            />
                        )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-2">
            <div className="card mb-3">
              <div className="card-body">
                <h6>Direct Purchase Program (optional)</h6>
                <p className="m-0">
                  For patients who don’t have insurance coverage, or choose not to use it, both GenVisc850*
                  and TriVisc* are available to purchase with a prescription directly from our exclusive
                  pharmacy network at a significant cost savings. &nbsp;
                  <a href="https://orthogenrx.com/healthcare-professionals/the-direct-purchase-program"
                     target="_blank" rel="noreferrer">
                    Click here for more info
                  </a>
                </p>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-lg-4 d-flex">
                  <Controller
                      name={`prescription_attributes.directPurchase`}
                      control={control}
                      defaultValue={false}
                      render={({field: {value, onChange}}) => (
                          <div className="checkbox-ui">
                              <input className="form-check-input d-none" type="checkbox"
                                      id="prescription_attributes.directPurchase"
                                      name="prescription_attributes.drug_type"
                                      onChange={(e) => changeDirectPurchase(onChange, e)}
                                      checked={value}
                              />
                              <label className="form-check-label card"
                                      htmlFor="prescription_attributes.directPurchase">
                                  <h6>Direct Purchase Enrollment</h6>
                                  <small className="d-block text-muted">
                                      by checking this box I would like to enroll my patient into the program
                                      immediately
                                      and do not wish to determine insurance benefits.
                                  </small>
                              </label>
                          </div>
                      )}
                  />
              </div>
              <div className="col-lg-4 d-flex">
                  <Controller
                      name="prescription_attributes.autoDirectPurchase"
                      control={control}
                      defaultValue={false}
                      render={({field: {value, onChange}}) => (
                          <div className="checkbox-ui">
                              <input className="form-check-input d-none" type="checkbox"
                                      id="prescription_attributes.autoDirectPurchase"
                                      name="prescription_attributes.drug_type"
                                      onChange={(e) => changeDirectPurchase(onChange, e)}
                                      checked={value}
                              />
                              <label className="form-check-label card"
                                      htmlFor="prescription_attributes.autoDirectPurchase">
                                  <h6>Benefit Investigation & Auto Direct Purchase</h6>
                                  <small className="d-block text-muted">
                                      by checking this box, I would like to run a benefit investigation and if no
                                      insurance coverage is found, transfer my prescription and enroll my patient
                                      into the Program.
                                  </small>
                              </label>
                          </div>
                      )}
                  />
              </div>
              <div className="col-lg-4 d-flex">
                  <Controller
                      name="prescription_attributes.benfitOnly"
                      control={control}
                      defaultValue={false}
                      render={({field: {value, onChange}}) => (
                          <div className="checkbox-ui">
                              <input className="form-check-input d-none" type="checkbox"
                                      id="prescription_attributes.benfitOnly"
                                      name="prescription_attributes.drug_type"
                                      onChange={(e) => changeDirectPurchase(onChange, e)}
                                      checked={value}
                              />
                              <label className="form-check-label card" htmlFor="prescription_attributes.benfitOnly">
                                  <h6>Benefit Investigation Only</h6>
                                  <small className="d-block text-muted">
                                      by checking this box, I would like HarmoKnee Solutions to only provide patient
                                      benefits
                                      and provide me with the results.
                                  </small>
                              </label>
                          </div>
                      )}
                  />
              </div>
              <div className="col-lg-4 d-flex">
                <Controller
                  name="category" control={control} defaultValue=""
                  render={({ fieldState: { error } }) => (
                    <>
                      {error && error.message ? (
                        <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small"></div>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </div>
          </div>

        </div>
        <Controller name="prescription_attributes.written_date" control={control}
                    defaultValue={format(new Date(), 'dd/MM/yyyy')}
                    render={({field: {value}}) => (
                        <div className="form-check mt-3">
                          <input className="form-check-input" type="checkbox" id="flexCheckIndeterminateDisabled"
                                 disabled
                                 checked/>
                          <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                            Dispense as Written (written date will be {value})
                          </label>
                        </div>
                    )}
        />
      </div>
    </div>
  );
};

export default CaseStepOne;
