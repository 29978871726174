import { InternalAxiosRequestConfig } from "axios";

export enum APITypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
};

export type APIRouteType = {
  url: string;
  method: APITypes;
  isBodyRequired: boolean;
  isQueryParamsRequired: boolean;
  isPathParamsRequired: boolean;
  isAuthHeaderRequired: boolean;
  isFileUpload: boolean;
};

export enum APIEndpoints {
  USER_SIGNIN = 'signIn',
  USER_FORGOT_PASSWORD = 'forgotPassword',
  USER_LOGOUT = 'logout',
  USER_RESET_PASSWORD = 'resetPassword',
  CASE_LIST = "caseList",
  GET_PROVIDER_SPECIALITIES = 'getProviderSpecialities',
  USER_UPDATE = 'userUpdate',
  ADD_USER = 'addUser',
  NPI_LOOKUP = 'npiLookup',
  ADD_PROVIDER = 'addProvider',
  PRACTICE_DROPDOWN = 'practiceDropdown',
  PATIENT_DROPDOWN = 'patientDropdown',
  PROVIDER_DROPDOWN = 'providerDropdown',
  ADD_ENROLLMENT = 'addEnrollment',
  GET_PROCEDURE_DROPDOWN = 'getProcedureDropdown',
  GET_DIAGNOSIS_DROPDOWN = 'getDiagnosisDropdown',
  GET_SITE_OF_CARE_DROPDOWN = 'getSiteOfCareDropdown',
  ADD_NEW_CASE = 'addNewCase',
  GET_PATIENT_DETAILS_BY_ID = 'getPatientDetailsById',
  CASE_DETAIL  = 'caseDetail',
  ADD_NOTE = "addNote",
  FETCH_DOCUMENT = 'fetchDocument',
  UPLOAD_CASE_FILE = "uploadCaseFile",
  DOWNLOAD_ZIP_FILE = 'downloadZipFile',
  CLONE_CASE_DETAILS = 'cloneCaseDetails',
  TREATMENT_LISTING = 'treatmentListing',
  ORDER_LISTING = 'orderListing',
  CREATE_ORDER = 'createOrder',
  TREATMENT_STATUS_UPDATE  = 'treatmentStatusUpdate',
  SCHEDULE_REP_VISIT = 'scheduleRepVisit',
  UPLOAD_PEF = 'uploadPEF',
  REQUEST_TREATMENT = 'requestTreatment',
  REQUEST_TREATMENT_NOT_TREATING = 'requestTreatmentNotTreating',
  DOWNLOAD_REQUEST_FORM = 'downloadRequestForm',
  MANUFACTURE_STATISTICS  = 'manufactureStatistics',
  MANUFACTURE_STATISTICS_PDF_DOWNLOAD = 'manufactureStatisticsPdfDownload',
  CASE_DETAIL_POPUP = 'caseDetailPopup',
  MANUFACTURE_STATISTICS_REPORT_DOWNLOAD  ='manufactureStatisticsReportsDownload',
  MANUFACTURE_STATISTICS_SOC_DOCTOR_DROPDOWN  ='manufactureStatisticsSocAndDoctorsDropdown',
  FETCH_PRECERT='fetchPrecert',
  FETCH_PRECERT_CASE_DETAIL='fetchPrecertCaseDetail',
  SUBMIT_PRECERT = 'submiPrecert',
  TRANSFER_RX = 'transferRX',
};

export type AuthAPIsType = {
  [key in APIEndpoints]: APIRouteType;
};

export type FetchHookType<TPathParams, TQueryParams, TResponseData> =
  (apiType: APIEndpoints, pathParams?: TPathParams, queryParams?: TQueryParams) =>
    { loading: boolean, error: string | null, data: TResponseData, mutate: <TBodyType>(body: TBodyType) => void };

export type UpdatedAxiosInstance<T> = InternalAxiosRequestConfig<T> & {
  pathParams: object;
};
