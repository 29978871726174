import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useFetch from "src/hooks/useFetch";
import { AuthContext } from "src/context/authContext";
import { ToastContext } from "src/context/toastContext";

import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { RenderNextStepProps, statetype } from "./types";
import { PrecertAPIError, PrecertAPIResponse } from "../precert/types";
import { LoaderContext } from "src/context/loaderContext";



const RenderNextStep: FC<RenderNextStepProps> = ({ kase, setDataForCancelModal, getCaseListing: { getListing, isCaseLoading } }): JSX.Element => {
  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const { setLoader, hideLoader, isVisible } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [precertPathParameters, setPrecertPathParameters] = useState<statetype>()





  const { error: fetchPrecertError, mutate: fetchPrecertMutate, data: fetchPrecertResponse } =
    useFetch<PrecertAPIResponse>(APIEndpoints.FETCH_PRECERT, {}, {}, false);

  useEffect(() => {
    if (fetchPrecertError) {
      const { errors, code } = fetchPrecertError as unknown as PrecertAPIError;
      showToast([errors || `Fetch Precert Error.Please try again`], ToastTypes.ERROR)
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrecertError]);

  useEffect(() => {
    if (fetchPrecertResponse) {
      navigate(`/cases/${precertPathParameters && precertPathParameters.case_id}/precerts/${precertPathParameters && precertPathParameters.id}/edit`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrecertResponse]);

  const navigateToPrecertForm = (caseId: number, precertId: number) => {
    const pathParams = {
      case_id: caseId,
      id: precertId
    }
    setPrecertPathParameters(pathParams)
    fetchPrecertMutate({}, pathParams)
  }




  const { loading: transferRxLoading, data: transferRxData, error: transferRxError, mutate: transferRxByCaseId } = 
    useFetch(APIEndpoints.TRANSFER_RX, {}, { case_id: kase.id });

  useEffect(() => {
    if (transferRxError) {
      showToast(["Something went wrong while calling server, Please try again in a while"], ToastTypes.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferRxError]);

  useEffect(() => {
    if (transferRxData) {
      const { message } = transferRxData as unknown as { code: number; message: string; };
      showToast([message], ToastTypes.SUCCESS);
      getListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferRxData]);

  useEffect(() => {
    if (transferRxLoading) setLoader();
    else hideLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferRxLoading]);




  const { data: treatmentAPIResponse, error: treatmentAPIError, mutate: makeCaseTreated } =
    useFetch(APIEndpoints.REQUEST_TREATMENT);

  useEffect(() => {
    if (treatmentAPIResponse || treatmentAPIError) {
      hideLoader();
      if (treatmentAPIResponse) {
        const { code, message } = treatmentAPIResponse as unknown as { code: number; message: string; };
        if (code === 200)
          showToast(["Patient Treated Successfully"], ToastTypes.SUCCESS);
        else
          showToast([message], ToastTypes.SUCCESS);
        getListing();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentAPIResponse, treatmentAPIError]);

  const callTreatedAPI = () => {
    setLoader();
    makeCaseTreated<{ case_id: number; practice_id: number; }, {}, {}>({ case_id: kase.id, practice_id: kase.doctor.practice_id });
  };





  let caseAction;
  let pdpAction;
  let transferAction;

  // if (kase.pdp_button) {
  //   pdpAction =
  //     <button className="btn btn-info fs-6 rounded-0 mx-1 my-1">
  //       <i className="bi bi-clipboard-data me-2"></i> Direct Purchase Program
  //     </button>
  // }

  if (kase.transfer_rx_button) {
    transferAction =
      <button
        className="btn btn-info fs-6 rounded-0 mx-1 my-1"
        onClick={() => transferRxByCaseId()}
        disabled={isCaseLoading}
      >
        <i className="bi bi-clipboard-data me-2"></i> Transfer Rx
      </button>
  }

  if (!kase.treatment && kase.active_precert) {
    caseAction =
      <button
        className="btn btn-warning text-white m-1 rounded-0"
        onClick={() => { navigateToPrecertForm(kase.id, kase.active_precert_id) }}
        disabled={isCaseLoading}
      >
        <i className="bi bi-clipboard-data me-2"></i> Upload Clinicals
      </button>
  } else if (kase.treated_button) {
    caseAction =
      <>
        <button
          className="btn btn-success fs-6 rounded-0 mx-1 my-1"
          onClick={callTreatedAPI}
          disabled={isVisible || isCaseLoading}
        >
          <i className="bi bi-check-lg me-2"></i> Treated
        </button>
        <button
          className="btn btn-danger fs-6 rounded-0 mx-1 my-1"
          onClick={() => setDataForCancelModal({ isModalVisible: true, caseId: kase.id, prescriptionId: kase.prescription_id })}
          disabled={isVisible || isCaseLoading}
        >
          <i className="bi bi-x-lg me-2"></i> Not Treating
        </button>
      </>
  } else {
    caseAction = !pdpAction && !transferAction ? <p className="fs-6 mb-0">No Action Required</p> : <></>;
  }

  return (
    <>
      {caseAction}
      {pdpAction}
      {transferAction}
    </>
  );
};

export default RenderNextStep;
