import { FC, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
// import { format } from "date-fns";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import CaseStepOne from "src/components/caseStepOne";
import CaseStepTwo from "src/components/caseStepTwo";
import CaseStepThree from "src/components/caseStepThree";
import RestoreCaseSessionModal from "./modal";

import { AddCaseProps, AddCaseStorageType, InsuranceAttributesProps, PatientPhoneNumber } from "./types";
import { AddCaseSchema } from "src/validation/addCase";
import { AuthContext } from "src/context/authContext";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import { DEFAULT_DRUG_ID } from "src/constants/cases";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";



const AddCase: FC = (): JSX.Element => {
  const [step, changeStep] = useState<number>(1);
  const [isStepTwoMounted, mountingStepTwo] = useState<boolean>(false);
  const [isModalVisible, showHideModal] = useState<boolean>(false);
  const [isStepOneInvalid, setStepOneValidity] = useState<boolean>(false);
  const [isStepTwoInvalid, setStepTwoValidity] = useState<boolean>(false);

  const { userData, destroySession } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const { control, formState, handleSubmit, getValues, watch, trigger, reset, setValue, resetField } = useForm<AddCaseProps>({
    mode: "all",
    resolver: yupResolver(AddCaseSchema),
    defaultValues: {
      mos: "online",
      submission_source: "provider",
      prescription_attributes: {
        drug_id: DEFAULT_DRUG_ID,
      },
      patient_attributes: {
        address: {
          address_name: "home",
        },
        phone_numbers: [
          {
            phone_type: "cell",
          } as PatientPhoneNumber,
        ],
      },
      insurances_attributes: [
        {
          global_insurance_id: null,
          is_primary_insurance: true,
          insurance_priority: 0,
        } as unknown as InsuranceAttributesProps,
      ],
    }
  });

  const { isDirty } = formState;
  const patientData = watch("patient_attributes");





  useEffect(() => {
    window.addEventListener('unload', () => {
      if (isDirty)
        localStorage.setItem('newCase', JSON.stringify({ newData: getValues(), step }));
    });

    const data = localStorage.getItem('newCase');
    if (data) showHideModal(true);

    return () => {
      window.removeEventListener('unload', () => {});
      if (isDirty)
        localStorage.setItem('newCase', JSON.stringify({ newData: getValues(), step }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patientData && patientData.global_patient_id)
      trigger(["patient_attributes", "insurances_attributes"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);





  const restoringCaseData = () => {
    const data = localStorage.getItem('newCase');
    if (data) {
      const { step, newData } = JSON.parse(data) as unknown as AddCaseStorageType;
      changeStep(Number(step));
      if (step >= 2) mountingStepTwo(true);
      reset(newData);

      if (step >= 2) {
        trigger(["procedure_attributes", "prescription_attributes"]);
        if (step > 2)
          trigger(["doctor_attributes", "patient_attributes", "insurances_attributes"]);
      }
    }
    localStorage.removeItem('newCase');
    showHideModal(false);
  };

  const removeCurrentData = () => {
    localStorage.removeItem('newCase');
    reset();
    showHideModal(false);
  };





  useEffect(() => {
    const { errors } = formState;

    if (Object.keys(errors).length > 0) {

      if (Object.hasOwn(errors, 'prescription_attributes') ||
        Object.hasOwn(errors, 'procedure_attributes') ||
        Object.hasOwn(errors, 'category')
      ) {
        setStepOneValidity(true);
      } else {
        setStepOneValidity(false);
      }

      if (Object.hasOwn(errors, 'patient_attributes') ||
        Object.hasOwn(errors, 'case_doctor') ||
        Object.hasOwn(errors, 'insurances_attributes')
      ) {
        setStepTwoValidity(true);
      } else {
        setStepTwoValidity(false);
      }
    } else {
      setStepOneValidity(false);
      setStepTwoValidity(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    const messages = [];
    const { errors } = formState;

    if (isStepOneInvalid && isStepTwoInvalid && step >= 2) {
      messages.push(`The following sections from step 1 have errors:
      ${errors.procedure_attributes ? '<br>- case procedure' : ''}
      ${errors.prescription_attributes ? '<br>- case prescription' : ''}
      ${errors.category ? '<br>- case type' : ''}`);

      messages.push(`The following sections from step 2 have errors: <br>
      ${errors.doctor_attributes ? '- case doctor<br>' : ''}
      ${errors && errors.patient_attributes ? '- case patient<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.hasOwnProperty("phone_numbers") ? '- case patient phone numbers<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.address ? '- case patient address<br>' : ''}
      ${errors.insurances_attributes ? '- case patient insurances' : ''}`);
    }
    else if (isStepOneInvalid && step >= 1) {
      messages.push(`The following sections from step 1 have errors:
      ${errors.procedure_attributes ? '<br>- case procedure' : ''}
      ${errors.prescription_attributes ? '<br>- case prescription' : ''}
      ${errors.category ? '<br>- case type' : ''}`);
    }
    else if (isStepTwoInvalid && step >= 2) {
      messages.push(`The following sections from step 2 have errors: <br>
      ${errors.doctor_attributes ? '- case doctor<br>' : ''}
      ${errors && errors.patient_attributes ? '- case patient<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.hasOwnProperty("phone_numbers") ? '- case patient phone numbers<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.address ? '- case patient address<br>' : ''}
      ${errors.insurances_attributes ? '- case patient insurances' : ''}`);
    }

    showToast(messages, ToastTypes.WARNING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepOneInvalid, isStepTwoInvalid, step]);


  const onStepProgressionClick = (localStep: number) => {
    if (step === 1)
      trigger(["procedure_attributes", "prescription_attributes", "category"]);
    else if (step === 2)
      trigger(["doctor_attributes", "patient_attributes", "insurances_attributes"]);

    if (localStep >= 2)
      mountingStepTwo(true);
    else if (localStep === 3)
      trigger(["procedure_attributes", "prescription_attributes", "category", "doctor_attributes", "patient_attributes", "insurances_attributes"]);

    changeStep(localStep);

    //:- logic to save case data in localstorage
    localStorage.setItem('newCase', JSON.stringify({ newData: getValues(), step }));
  };





  /**
   * @AddNewCase form handling for case data submission
   * @fourth case submit handler
   */
  const { loading: addCaseLoading, error: addCaseError, data: addCaseData, mutate: addNewCase } =
    useFetch<{ status: number }>(APIEndpoints.ADD_NEW_CASE);

  useEffect(() => {
    if (addCaseError) {
      const { code, error } = addCaseError as unknown as { code: number; error: string };
      if (code === 401) {
        showToast(['You need to sign up or sign in to continue'], ToastTypes.ERROR);
        destroySession();
        navigate('/users/sign_in');
      } else {
        showToast([error || `Something went wrong while adding new case. Please try again in a while.`], ToastTypes.ERROR);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCaseError]);

  useEffect(() => {
    if (addCaseData && addCaseData.status === 200) {
      localStorage.removeItem('newCase');
      showToast([`Your request submitted successfully!`], ToastTypes.SUCCESS);
      navigate(`/cases`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCaseData]);

  /** @fourth */
  const caseDataSubmission = (values: AddCaseProps) => {
    const newValues = {
      ...values,
      procedures_attributes: [
        { cpt_code: values.procedure_attributes.cpt_code }
      ],
      prescription_attributes: {
        ...values.prescription_attributes,
        diagnosis_code: values.prescription_attributes.diagnosis_code.join(","),
      },
      patient_firstlastname: `${values.patient_attributes.firstname} ${values.patient_attributes.lastname}`,
      patient_fullname: `${values.patient_attributes.firstname} ${values.patient_attributes.middlename} ${values.patient_attributes.lastname}`,
      patient_dob: values.patient_attributes.dob,
      insurances_attributes: values.insurances_attributes?.map((insurance, index) => {
        insurance.insurance_priority = index;
        return insurance;
      }),
      patient_attributes: {
        ...values.patient_attributes,
        ...(values.patient_attributes.patient_email && {
          patient_email: {
            id: values.patient_attributes.patient_email_id,
            email: values.patient_attributes.patient_email
          }
        })
      },
    };

    const { procedure_attributes, ...actualCaseRequest } = newValues;
    addNewCase({ case: actualCaseRequest });
  };





  return (
    <div className="d-flex flex-fill bg-light overflow-auto">
      <div className="container d-flex flex-column flex-fill">
        <AuthSubHeader/>
        <form className="d-flex flex-fill flex-column" onSubmit={handleSubmit(caseDataSubmission)}>
          <div className="bg-light py-3 sticky-top top-0 z-1">
            <div id="case-creation-progress-bar" className="case-progress-bar">
              <button
                type="button" id="progress-step-1"
                className={`btn case-creation-progress-step ${step >= 1 ? isStepOneInvalid ? 'invalid-progress-step' : 'active-progress-step' : ''}`}
                onClick={() => onStepProgressionClick(1)}
              >
                1. Getting Started <i className={`bi bi-play-fill progression-arrow-head`}></i>
              </button>

              <button
                type="button" id="progress-step-2"
                className={`btn case-creation-progress-step ${step >= 2 ? isStepTwoInvalid ? 'invalid-progress-step' : 'active-progress-step' : ''}`}
                onClick={() => onStepProgressionClick(2)}
              >
                2. Patient Information <i className={`bi bi-play-fill progression-arrow-head`}></i>
              </button>

              <button
                type="button" id="progress-step-3"
                className={`btn case-creation-progress-step ${step >= 3 ? 'active-progress-step' : ''}`}
                onClick={() => onStepProgressionClick(3)}
              >
                3. Finish
              </button>
            </div>
          </div>

          {userData ? (
            <>
              <Controller
                name="creator_name" control={control}
                defaultValue={userData.name} render={() => (<></>)}
              />
              <Controller
                name="user_id" control={control}
                defaultValue={userData.id} render={() => (<></>)}
              />
            </>
          ) : (<></>)}

          <div id="step-1" className="case-creation-step">
            {step >= 1 &&
              <CaseStepOne control={control} watch={watch} setValue={setValue} getValues={getValues}
                isVisible={step === 1}
              />
            }
          </div>
          <div id="step-2" className="case-creation-step">
            {isStepTwoMounted &&
              <CaseStepTwo resetField={resetField} control={control} getValues={getValues} watch={watch}
                setValue={setValue} isVisible={step === 2} trigger={trigger}
              />
            }
          </div>
          <div id="step-3" className="case-creation-step">
            {step === 3 && <CaseStepThree getValues={getValues}/>}
          </div>

          <div className="mt-2 d-flex">
            <button type="button" key="previousStepButton"
              className={`me-3 btn btn-outline-secondary ${step === 1 ? 'd-none' : ''}`}
              onClick={() => onStepProgressionClick(step - 1)}
            >
              <i className="bi bi-chevron-double-left"></i> Previous
            </button>

            {step === 3 ? (
              <button type="submit" disabled={addCaseLoading} key="formSubmitButton"
                className="btn btn-primary d-flex"
              >
                {addCaseLoading ? (
                  <i className="spinner-border text-white me-2" style={{width: `24px`, height: `24px`}}></i>
                ) : null}
                <p className="col-auto mb-0">Submit Request</p>
              </button>
            ) : (
              <button type="button" key="nextStepButton" className="btn btn-outline-secondary"
                onClick={() => onStepProgressionClick(step + 1)}
              >
                Next <i className="bi bi-chevron-double-right"></i>
              </button>
            )}
          </div>
        </form>
        <div className="mb-4"><FooterText footerType="black"/></div>

        <RestoreCaseSessionModal
          isModalVisible={isModalVisible} removeCurrentData={removeCurrentData}
          restoringCaseData={restoringCaseData}
        />
      </div>
    </div>
  );
};

export default AddCase;
