

import { FC, useEffect, useContext, useState } from "react";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import FooterText from "src/components/footerText";
import { CasedetailObjectType, StatisticsApiResponse, statisticsErrorAndData, StatisticsTerritories } from "./types";
import CaseVolumeHorizontal from "src/components/manufacturer/caseVolumeHorizontal";
import PieChartTerritories from "src/components/manufacturer/pieChartTerritories";
import Practices from "src/components/manufacturer/practices";
import Provider from "src/components/manufacturer/provider";
import CaseDetail from "src/components/manufacturer/caseDetail";
import TerritoriesFilter from "src/components/territoriesFilter";
import OngoingTreatment from "src/components/manufacturer/ongoingTreatment";
import { StatisticsTerritoriesSearchProps } from "src/components/territoriesFilter/types";
import { providerListType } from "src/components/manufacturer/provider/types";

const Territories: FC = (): JSX.Element => {

  const { destroySession } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let [searchParams, updateSearchParams] = useSearchParams();


  const [queryParams, changeQueryParams] = useState({
    start_date: searchParams.get('start_date') || format((new Date()).setDate((new Date()).getDate() - 30), "yyyy-MM-dd"),
    end_date: searchParams.get('end_date') || format((new Date()).setDate((new Date()).getDate()), "yyyy-MM-dd"),
    period: searchParams.get('period') || "",
    filter: searchParams.get('filter') || "",
    time: searchParams.get('time') || "",
    type: "index"
  });


  const { data: statisticsData, error: errorAndData, mutate: getStatsData } =
    useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS, queryParams, {}, true);

  useEffect(() => {
    hideLoader();
    if (errorAndData) {
      const { error, code } = errorAndData as unknown as statisticsErrorAndData;
      hideLoader();
      showToast([error || `Can't fetch statistics right now. Please try again in a while.`], ToastTypes.ERROR);

      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAndData]);

  useEffect(() => {
    if (statisticsData) {
      hideLoader();
      if (statisticsData.code === 200) {
        showToast([statisticsData.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);

  const updateQueryParamsAndCallAPI = (updatedQueries: StatisticsTerritoriesSearchProps, isReset: boolean = false): void => {
    if (isReset) {
      updateSearchParams({});
      changeQueryParams({
        start_date: format((new Date()).setDate((new Date()).getDate() - 30), "yyyy-MM-dd"),
        end_date: format((new Date()).setDate((new Date()).getDate()), "yyyy-MM-dd"),
        period: "",
        filter: "",
        time: "",
        type: ""

      });
    } else {
      changeQueryParams(updatedQueries);
    }

    setLoader()
    getStatsData({}, {}, updatedQueries);
  };


  return (
    <div className="container-fluid auth-page mb-4 overflow-x-hidden">
      <TerritoriesFilter defaultQueryParams={queryParams} updateQueryParams={updateQueryParamsAndCallAPI} />
      <div className="mt-3">
        {statisticsData && <CaseVolumeHorizontal StatisticsObjectType={statisticsData.data} />}
      </div>
      <div className="mt-3">
        {statisticsData && <PieChartTerritories StatisticsObjectType={statisticsData.data} />}

      </div>

      <div className="row">
        <div className="col-6 mt-3">
          <Practices />
        </div>
        <div className="col-6 mt-3">
          <Provider providerList={StatisticsTerritories.data.provider as unknown as providerListType[]} />
        </div>
      </div>
      <div className="row">
        <div className="mt-3">
          <CaseDetail baseUrl="/statistics" searchParams={queryParams} CaseDetailListType={StatisticsTerritories?.data?.case_details as unknown as CasedetailObjectType[]} />
        </div>
        <div className="mt-3">
          <OngoingTreatment baseUrl="/statistics" searchParams={queryParams} CaseDetailListType={StatisticsTerritories?.data?.case_details as unknown as CasedetailObjectType[]} />
        </div>
      </div>

      <FooterText footerType="black" />
    </div>

  );
};

export default Territories;
